import React from 'react'
import aboutUsPhoto from '../images/aboutUsPhoto.png'
import phone from '../images/phone.png'

export default function AboutUsContent() {
  return <div className = 'about-us'>
    <div className = 'about-us__image'>
      <img src = {aboutUsPhoto} alt = '' />
    </div>
    <div className = 'about-us__details'>
      <div className = 'about-us__details_name'>
        Фотокабинет "Азъ"
      </div>
      <div className = 'about-us__details_description'>
        Первые и единственные в Беларуси делаем фотопортреты по ретро-технологиям. Амбротип (на стекле). Тинтайп (на алюминиевой пластине)
      </div>
      <div className = 'about-us__details_contacts'>
        <div className = 'phone'>
          <img src = {phone} alt = '' />
          <div className = 'phone__record'>
            <div className = 'phone__record_sign'>
              Запись на фотосессию
            </div>
            <div className = 'phone__record_number'>
              +375 44 784 99 95
            </div>
          </div>
        </div>
        <div className = 'address'>
          <div className = 'address__about'>
            Наш адрес
          </div>
          <div className = 'address__city'>
            г.Минск
          </div>
          <div className = 'address__e-mail'>
            admin@ambrotype.by
          </div>
        </div>
      </div>
    </div>
  </div>
}
