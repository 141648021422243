import React from 'react'
import AboutUsContent from '../components/AboutUsContent'
import Layout from '../components/Layout'

export default function Index() {
  return (
    <Layout>
      <AboutUsContent />
    </Layout>
  );
}
